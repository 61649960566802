import { http } from "http";
import { withAPI } from "natur-immer";

const state = {
  tableData: [],
  pageInfo: {
    total: 0,
    pageSize: 10,
    pageNum: 1,
    isArchived: "all", // "all" | "false" | "true"
    name: "",
  },
};
const maps = {
  pageCount: [
    "pageInfo.total",
    "pageInfo.pageSize",
    (total, pageSize) => Math.ceil(total / pageSize),
  ],
};

const actions = {
  updateName: withAPI(async (name, { setState }) =>
    setState((s) => {
      s.pageInfo.name = name;
    })
  ),
  updatePageInfo: withAPI(async (pageInfo, { setState }) =>
    setState((s) => {
      s.pageInfo = Object.assign(s.pageInfo, pageInfo);
    })
  ),
  updateIsArchivedQueryParams: (isArchived) => (api) =>
    api.setState((s) => {
      s.pageInfo.isArchived = isArchived;
    }),
  updateIsArchived:
    (isArchived, dealPlanId) =>
    async ({ setState, getState, localDispatch }) => {
      await http.patch(`/dealplan/isArchived/:${dealPlanId}`, {
        isArchived,
      });
      return localDispatch("fetchDealPlanList");
    },
  multiUpdateIsArchived:
    (ids, isArchived) =>
    async ({ setState, getState, localDispatch }) => {
      await http.put(`/dealplan/multi/isArchived`, {
        ids,
        isArchived,
      });
      return localDispatch("fetchDealPlanList");
    },
  multiDeleteDealPlanner:
    (ids, isArchived) =>
    async ({ setState, getState, localDispatch }) => {
      await http.post(`/dealplan/multi/delete`, {
        ids,
      });
      return localDispatch("fetchDealPlanList");
    },

  fetchDealPlanList:
    () =>
    async ({ getState, setState }) => {
      const { pageNum, pageSize, name, isArchived } = getState().pageInfo;
      const res = await http.post("/dealplans", {
        page: pageNum || 1,
        pageSize: pageSize || 10,
        keyword: name,
        condition: {
          isArchived: isArchived === "all" ? undefined : isArchived === "true",
        },
      });
      return setState((ns) => {
        ns.pageInfo.total = res?.data?.total;
        ns.tableData = res?.data?.dealPlans;
      });
    },
  deleteDealPlan: withAPI(async (id, { localDispatch }) => {
    await http.delete(`/dealplan/${id}`);
    return localDispatch("fetchDealPlanList");
  }),
};

export default {
  state,
  maps,
  actions,
};
