/* eslint-disable jsx-a11y/label-has-associated-control */
// @mui material components

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Grid } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import MDButton from "components/MDButton";
import { useState } from "react";
import { useFlatInject } from "store";
import { Message } from "utils/message";

function CardForm({ setOpen }) {
  const stripe = useStripe();
  const elements = useElements();
  const [user] = useFlatInject("user");
  const { addPaymentMethod } = user;

  const [checkerCardNumber, setCheckerCardNumber] = useState({
    isDirty: false,
    isInvalid: false,
    isCompleted: false,
  });

  const [checkerCvcCard, setCheckerCvcCard] = useState({
    isDirty: false,
    isInvalid: false,
    isCompleted: false,
  });

  const [checkerExpires, setCheckerExpires] = useState({
    isDirty: false,
    isInvalid: false,
    isCompleted: false,
  });

  const [checkerName, setCheckerName] = useState({
    value: "",
    isDirty: false,
  });

  const callCheckCard = (elementId, setState) => {
    const cardContainer = document.getElementById(elementId);

    if (cardContainer.classList.contains("StripeElement--complete")) {
      setState((prev) => ({ ...prev, isCompleted: true, isInvalid: false }));
      return;
    }

    if (cardContainer.classList.contains("StripeElement--focus")) {
      setState((prev) => ({ ...prev, isInvalid: false }));
      return;
    }

    if (
      cardContainer.classList.contains("StripeElement--empty") ||
      cardContainer.classList.contains("StripeElement--invalid")
    ) {
      setState((prev) => ({ ...prev, isInvalid: true, isCompleted: false }));
    } else {
      setState((prev) => ({ ...prev, isInvalid: false }));
    }
  };

  const setDirty = (setState) => {
    setState((prev) => ({ ...prev, isDirty: true }));
  };

  const setName = (field, value) => {
    setCheckerName((prev) => ({ ...prev, [field]: value }));
  };

  const savePaymentMethod = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardNumberElement);
    const nameCardValue = document.getElementById("name-on-card-field").value;

    const paramsStripe = {
      name: nameCardValue,
    };
    try {
      const payload = await stripe.createToken(card, paramsStripe);
      // console.log("[PaymentMethod]", payload?.token?.id);

      const res = await addPaymentMethod(payload?.token?.id);
      if (res.code !== 0) throw new Error("error");
      Message.success("Payment method updated");
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const stylesStripe = {
    style: {
      base: {
        fontSize: "16px",
        fontWeight: 400,
        ":-webkit-autofill": {
          color: "#282828",
        },
        "::placeholder": {
          color: "#757575",
        },
      },
    },
  };

  return (
    <form>
      <MDBox sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }} p={4}>
        <MDTypography fontSize={24} fontWeight={500} sx={{ color: "#282828" }}>
          Payment method
        </MDTypography>
        <MDBox sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
          <MDButton
            variant="contained"
            color="green"
            sx={{
              padding: "5px 20px",
              fontSize: "14px",
              border: "1px solid transparent",
              height: "39px",
            }}
            onClick={savePaymentMethod}
            disabled={
              !checkerCardNumber.isCompleted ||
              !checkerCvcCard.isCompleted ||
              !checkerExpires.isCompleted ||
              !checkerName.value
            }
          >
            Save
          </MDButton>
          <MDButton
            variant="outlined"
            color="green"
            sx={{
              padding: "5px 20px",
              fontSize: "14px",
              height: "39px",
            }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </MDButton>
        </MDBox>
      </MDBox>
      <Grid container p={4} pt={0} spacing={3}>
        {/* <Grid item xs={12} lg={6}>
          <MDTypography fontSize={12} sx={{ color: "#282828" }}>
            Card type
          </MDTypography>
          <MDBox
            sx={{
              borderBottom: "1px solid rgba(117, 117, 117, 0.56)",
              paddingBottom: "3px",
              width: "100%",
            }}
          >
            <input
              id="card-type-field"
              placeholder="VISA / MC / AMEX / ect."
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#282828",
                border: "none",
                outline: "none",
              }}
            />
          </MDBox>
        </Grid> */}
        <Grid item xs={12} lg={6}>
          <MDTypography fontSize={12} sx={{ color: "#282828" }}>
            Name on card
          </MDTypography>
          <MDBox
            sx={{
              borderBottom: "1px solid rgba(117, 117, 117, 0.56)",
              paddingBottom: "3px",
              width: "100%",
            }}
          >
            <input
              id="name-on-card-field"
              placeholder="Your name"
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#282828",
                border: "none",
                outline: "none",
              }}
              onChange={(e) => setName("value", e.target.value)}
              onFocus={() => setName("isDirty", true)}
            />
          </MDBox>
          {checkerName.isDirty && !checkerName.value && (
            <MDTypography fontSize={10} sx={{ color: "red" }}>
              Name on card is invalid
            </MDTypography>
          )}
        </Grid>

        <Grid item xs={12} lg={6}>
          <MDTypography fontSize={12} sx={{ color: "#282828" }}>
            Card number
          </MDTypography>
          <MDBox
            sx={{
              borderBottom: "1px solid rgba(117, 117, 117, 0.56)",
              padding: "8px 0",
              width: "100%",
            }}
          >
            <CardNumberElement
              id="card-number"
              options={stylesStripe}
              onBlur={() => callCheckCard("card-number", setCheckerCardNumber)}
              onFocus={() => setDirty(setCheckerCardNumber)}
            />
          </MDBox>
          {checkerCardNumber.isDirty && checkerCardNumber.isInvalid && (
            <MDTypography fontSize={10} sx={{ color: "red" }}>
              Card number is invalid
            </MDTypography>
          )}
        </Grid>

        {/* <Grid item xs={12} lg={6}>
          <MDTypography fontSize={12} sx={{ color: "#282828" }}>
            Address
          </MDTypography>
          <MDBox
            sx={{
              borderBottom: "1px solid rgba(117, 117, 117, 0.56)",
              paddingBottom: "3px",
              width: "100%",
            }}
          >
            <input
              id="address-field"
              placeholder="Your address"
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#282828",
                border: "none",
                outline: "none",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MDTypography fontSize={12} sx={{ color: "#282828" }}>
            City
          </MDTypography>
          <MDBox
            sx={{
              borderBottom: "1px solid rgba(117, 117, 117, 0.56)",
              paddingBottom: "3px",
              width: "100%",
            }}
          >
            <input
              id="city-field"
              placeholder="Your city"
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#282828",
                border: "none",
                outline: "none",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MDTypography fontSize={12} sx={{ color: "#282828" }}>
            State
          </MDTypography>
          <MDBox
            sx={{
              borderBottom: "1px solid rgba(117, 117, 117, 0.56)",
              paddingBottom: "3px",
              width: "100%",
            }}
          >
            <input
              id="state-field"
              placeholder="Your state"
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#282828",
                border: "none",
                outline: "none",
              }}
            />
          </MDBox>
        </Grid> */}

        <Grid item xs={12} lg={6}>
          <MDTypography fontSize={12} sx={{ color: "#282828" }}>
            CVC
          </MDTypography>
          <MDBox
            sx={{
              borderBottom: "1px solid rgba(117, 117, 117, 0.56)",
              padding: "8px 0",
              width: "100%",
            }}
          >
            <CardCvcElement
              id="card-cvc"
              options={stylesStripe}
              onBlur={() => callCheckCard("card-cvc", setCheckerCvcCard)}
              onFocus={() => setDirty(setCheckerCvcCard)}
            />
          </MDBox>
          {checkerCvcCard.isDirty && checkerCvcCard.isInvalid && (
            <MDTypography fontSize={10} sx={{ color: "red" }}>
              CVC number is invalid
            </MDTypography>
          )}
        </Grid>

        <Grid item xs={12} lg={6}>
          <MDTypography fontSize={12} sx={{ color: "#282828" }}>
            Expiration date
          </MDTypography>
          <MDBox
            sx={{
              borderBottom: "1px solid rgba(117, 117, 117, 0.56)",
              padding: "8px 0",
              width: "100%",
            }}
          >
            <CardExpiryElement
              id="card-expiry"
              onBlur={() => callCheckCard("card-expiry", setCheckerExpires)}
              onFocus={() => setDirty(setCheckerExpires)}
              options={stylesStripe}
            />
          </MDBox>
          {checkerExpires.isDirty && checkerExpires.isInvalid && (
            <MDTypography fontSize={10} sx={{ color: "red" }}>
              Expires date is invalid
            </MDTypography>
          )}
        </Grid>

        <Grid item xs={12} display={{ xs: "block", md: "none" }}>
          <MDButton
            variant="contained"
            color="green"
            sx={{
              padding: "5px 20px",
              fontSize: "14px",
              border: "1px solid transparent",
              height: "39px",
              width: "100%",
            }}
            onClick={savePaymentMethod}
            disabled={
              !checkerCardNumber.isCompleted ||
              !checkerCvcCard.isCompleted ||
              !checkerExpires.isCompleted ||
              !checkerName.value
            }
          >
            Save
          </MDButton>
        </Grid>

        <Grid item xs={12} display={{ xs: "block", md: "none", paddingTop: "12px !important" }}>
          <MDButton
            variant="outlined"
            color="green"
            sx={{
              padding: "5px 20px",
              fontSize: "14px",
              height: "39px",
              width: "100%",
            }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </MDButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default CardForm;
